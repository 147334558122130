import * as React from 'react'
import HeroBan from '../components/index/HeroBan'
import Layout from '../components/Layout'
import EstimateBanner from '../components/index/EstimateBanner'
import ContactForm from '../components/ContactForm'
import RequestPlumber from '../components/index/RequestPlumber'
import InstagramGallery from '../components/index/InstagramGallery'
import Services from '../components/index/Services'
import Seo from '../components/Seo'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title='Home'/>
      <HeroBan />
      <RequestPlumber />
      <Services />
      <EstimateBanner />
      <ContactForm />
      <InstagramGallery />
    </Layout>
  )
}

export default IndexPage
