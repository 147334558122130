import React from 'react'
import styled from 'styled-components'
import { primaryColor } from '../../styles/styleVariables'
import { Button } from '../Button'

const RequestPlumber = () => {
  return (
    <RequestSection>
      <PlumbingContainer>
        <ProblemText>Plumbing Problem?</ProblemText>
        <HelpText>We can help you!</HelpText>
      </PlumbingContainer>
      <BlurbContainer>
        <BlurbHeader>
          Professional Plumbing, Leak and Heating Services
        </BlurbHeader>
        <BlurbText>
          Whether you're planning a renovation or simply need routine
          maintenance on your home or office plumbing systems, our professional
          technicians and mechanics can assist you effectively in the right way.
        </BlurbText>
        <ButtonContainer>
          <Button color='whiteTrans' to='/services'>
            our services
          </Button>
          <Button color='blueFill' to='/contact'>
            Get a quote
          </Button>
        </ButtonContainer>
      </BlurbContainer>
    </RequestSection>
  )
}

export default RequestPlumber

const RequestSection = styled.aside`
  display: flex;
  justify-content: center;
  padding: 8rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
  }
`

const PlumbingContainer = styled.div`
  width: 30%;
  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
    width: 100%;
  }
`

const ProblemText = styled.h3`
  font-size: var(--step-3);
  text-transform: uppercase;
  color: ${primaryColor};

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const HelpText = styled.p`
  color: ${primaryColor};
  font-size: var(--step-1);

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const BlurbContainer = styled.div`
  width: 50%;
  margin-left: 1rem;
  @media screen and (max-width: 768px) {
    text-align: center;
    width: 80%;
  }
`

const BlurbHeader = styled.h3`
  font-size: var(--step-0);
  margin-bottom: 1.5rem;
`

const BlurbText = styled.p`
  margin-bottom: 3rem;
  font-size: var(--step--2);
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    &:first-child {
      margin-right: 2rem;
    }
    &:nth-child(2) {
      margin-left: 2rem;
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    > * {
      &:first-child {
        margin-right: 0;
        margin-bottom: 2rem;
      }
      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
`
