import React from 'react'
import styled, { keyframes } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { locationsData } from '../../data/locationsData'
import { TitleH1 } from '../Titles'

const HeroBan = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "herobanner.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              webpOptions: { quality: 100 }
              formats: WEBP
              layout: FULL_WIDTH
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <HeroContainer Tag='div' {...bgImage} preserveStackingContext>
      <Overlay>
        <HeroTextContainer>
          <TitleH1>Servicing </TitleH1>
          <TitleContainer>
            <AnimationList>
              {locationsData.map((item, index) => (
                <AnimationItem key={index}>
                  <TitleH1>{item}</TitleH1>
                </AnimationItem>
              ))}
            </AnimationList>
          </TitleContainer>
          <SubTitle>for home owners and businesses</SubTitle>
        </HeroTextContainer>
      </Overlay>
    </HeroContainer>
  )
}

export default HeroBan

const HeroContainer = styled(BackgroundImage)`
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 13rem max(calc((100vw - 1300px) / 2), 2rem);
`

const TitleContainer = styled.div`
  display: flex;
  overflow: hidden;
  > * {
    &:first-child {
      margin-right: 1rem;
    }
  }
`

const change = keyframes`
    0% {transform:translate3d(0,0,0);}
    28% {transform:translate3d(0,-100%,0);}
    56% {transform:translate3d(0,-200%,0);}
    84% {transform:translate3d(0,-100%,0);}
`

const AnimationList = styled.ul`
  margin-top: 0;
  list-style: none;
  /* animation-name: ${change};
  animation-duration: 10s;
  animation-iteration-count: infinite; */
  animation: ${change} 10s ease-in-out infinite;
  height: 70px;
`

const AnimationItem = styled.li`
  line-height: 70px;
  margin: 0;
`

const SubTitle = styled.h2`
  color: white;
  font-weight: 500;
  font-size: var(--step-1);
`
