import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { TitleH2 } from '../Titles'

const InstagramGallery = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent(limit: 9) {
        edges {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 350
                  aspectRatio: 1
                )
              }
            }
            caption
          }
        }
      }
    }
  `)

  return (
    <ProjectContainer aria-labelledby='recent-projects'>
      <TitleH2 id='recent-projects'>Our Recent Projects</TitleH2>
      <InstaContainer>
        {data.allInstagramContent.edges.map((item, index) => (
          <InstaPic
            key={index}
            image={getImage(
              item.node.localFile.childImageSharp.gatsbyImageData
            )}
            alt={item.node.caption}
          />
        ))}
      </InstaContainer>
    </ProjectContainer>
  )
}

export default InstagramGallery

const ProjectContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
`

const InstaContainer = styled.section`
  padding: 4rem 0 0 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const InstaPic = styled(GatsbyImage)`
  transition: 0.5s;
  &:hover {
    transform: scale(1.05);
  }
`
