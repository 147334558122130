import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { TitleH1 } from '../Titles'
import { Button } from '../Button'

const EstimateBanner = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "chrisjobwashroom.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              webpOptions: { quality: 100 }
              formats: WEBP
              layout: FULL_WIDTH
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <EstimateContainer Tag='div' {...bgImage} preserveStackingContext>
      <Overlay>
        <EstimateContent>
          <TitleH1>
            Delivering Elite <br />
            Plumbing Services
          </TitleH1>
          <Button color='whiteFill' to='/contact'>
            request an estimate
          </Button>
        </EstimateContent>
      </Overlay>
    </EstimateContainer>
  )
}

export default EstimateBanner

const EstimateContainer = styled(BackgroundImage)`
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`

const EstimateContent = styled.div`
  padding: 13rem max(calc((100vw - 1300px) / 2), 2rem);
  gap: 2rem;

  > * {
    &:first-child {
      margin-bottom: 4rem;
    }
  }
`
