import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Button } from '../Button'
import { TitleH2 } from '../Titles'

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allServicesDataJson {
        edges {
          node {
            alt
            blurb
            button
            title
            link
            img {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1
                  height: 277
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ServicesSection aria-labelledby='services'>
      <ServicesWrapper>
        <TitleH2 id='services'>Our Services</TitleH2>
        <ServiceList>
          {data.allServicesDataJson.edges.map((item, index) => (
            <ServiceCard key={index}>
              <ServiceImg
                image={getImage(item.node.img.childImageSharp.gatsbyImageData)}
                alt={item.node.alt}
              />
              <ServiceInfo>
                <ServiceTitle>{item.node.title}</ServiceTitle>
                <ServiceBlurb>{item.node.blurb}</ServiceBlurb>
              </ServiceInfo>
              <Button color='whiteTrans' to={item.node.link}>
                {item.node.button}
              </Button>
            </ServiceCard>
          ))}
        </ServiceList>
      </ServicesWrapper>
    </ServicesSection>
  )
}

export default Services

const ServicesSection = styled.section`
  background-color: #f9f9fb;
  width: 100vw;
`

const ServicesWrapper = styled.div`
  padding: 4rem max(calc((100vw - 1300px) / 2), 2rem) 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ServiceList = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6rem;
`

const ServiceCard = styled.div`
  max-width: 22.5rem;
  min-height: 40rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  > * {
    &:last-child {
      margin-bottom: 2rem;
      margin-top: auto;
    }
  }
`

const ServiceImg = styled(GatsbyImage)`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  margin-bottom: 2rem;
`

const ServiceInfo = styled.div`
  padding: 0 3rem;
`

const ServiceTitle = styled.h3`
  font-size: var(--step--1);
  margin-bottom: 1rem;
`

const ServiceBlurb = styled.p`
  font-size: var(--step--2);
  margin-bottom: 1rem;
`
